<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="查看视频"
                left-arrow
                @click-left="$router.go(-1)"
        />

<!--        <video @src="url"></video>-->
        <video controls="controls"  width='100%;' height="381px" padding-top='-6px' id="videoInfo" :src="url">
            <source type="video/mp4"/>
        </video>
        <div style="margin: 16px;">
            <Button round block type="danger" native-type="submit" @click="dropVideo">删除视频</Button>
        </div>
        <RLFooter/>
    </div>
</template>

<script>

    import {Button,Dialog} from 'vant';
    import {deleteVideo} from '@/api/upload';

    export default {
        name: 'QueIdx',
        components: {
            Button
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:false,
                activeTab: 0,
                url:'',
                rid:'',
            };
        },
        computed:{

        },
        methods:{
            // initidx(){
            //     console.info(this.$store.state.user);
            //     //this.$store.commit('updatePid',this.$route.params.pid);
            //     let query = this.$route.query;
            //
            //     let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
            //     getFollows({follow_time:follow_time,pid:this.$route.params.pid}).then((response) => {
            //         if(response.code==200 ){
            //             // this.loading=true;
            //             this.active=response.data.total;
            //
            //             this.followcrfs=response.data.rows;
            //             console.log(response.data)
            //
            //             if(this.followcrfs.length==0){
            //                 this.nodata = true
            //             }
            //         }
            //     });
            //     // getqueidxs({pid:this.$route.params.pid}).then((response) => {
            //     //     this.loading=true;
            //     //     if(this.followcrfs.length==0){
            //     //         this.followcrfs=response.data;
            //     //     }
            //     // });
            // },
            showQue(idx){
                this.$router.push({path: '/que/'+idx.id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            dropVideo(){
                Dialog.confirm({
                    title: '删除',
                    message: '确认要删除该视频吗？',
                }).then(() => {
                        // on confirm
                    deleteVideo({video_id:this.rid}).then((response) => {
                            console.log(response)
                            if(response.code==200 ){
                                // this.loading=true;
                                // this.files=response.data.rows;
                                console.log(response)
                                this.$router.push({path:'/upload'});
                                this.$toast.success('删除成功');

                            }
                        });
                    }).catch(() => {
                        // on cancel
                    });

            }
        }
        ,
        created() {
            /*初始化列表*/
            // this.initidx();
            // let videoUrl = this.$route.params.url
            let query = this.$route.query;
            this.url=query.url;
            this.rid=query.rid;
            console.log(this.url)
            // let videos = document.getElementById('videoInfo');
            // videos.src = this.url;

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
